import {EximProducts, Path, ProfileTabs} from '@common/constants';
import {getUserInitials} from '@common/helpers';
import {CompanyProfile, SolidDownAngle, UserManagement} from '@shared/icons';
import {RootState} from '@store';
import Link from '@submodules/Link/Link';
import {ReactNode, memo, useCallback, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link as RouterLink, useLocation} from 'react-router-dom';

import {SettingsDropdown, UserHeaderDropdown} from './DropdownList';
import {IData} from './config';
import settingsDropdown from './settingsDropdown';

interface IMenu {
  data: IData[];
}

function Menu(props: IMenu): JSX.Element {
  const {data} = props;
  const [menuData, setMenuData] = useState(data);
  const domNode = useRef([]) as React.MutableRefObject<HTMLButtonElement[]>;
  const {pathname} = useLocation();

  const {shouldCompanyProfileShow, selectedProductName} = useSelector(
    (state: RootState) => state.profile
  );

  // if data is change then render the menuData
  useEffect(() => {
    setMenuData(data);
  }, [data]);

  const handleDropDown = useCallback(
    (id: string) => {
      const updatedData = menuData.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            hasActiveDropdown: !item.hasActiveDropdown,
          };
        }
        return {...item, hasActiveDropdown: false};
      });
      setMenuData(updatedData);
    },
    [menuData]
  );

  const handleCloseDropDown = useCallback(() => {
    const updatedData = menuData.map((item) => ({
      ...item,
      hasActiveDropdown: false,
    }));
    setMenuData(updatedData);
  }, [menuData]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;

      if (
        (typeof targetElement?.className === 'string' &&
          targetElement.className?.includes('header-link-element')) ||
        (typeof targetElement.className === 'string' &&
          targetElement.parentElement?.className?.includes(
            'header-link-element'
          ))
      ) {
        return;
      }
      const svgTarget = (event.target as SVGElement).parentNode?.parentElement;

      const pathTarget = (event.target as SVGAElement).parentNode?.parentNode
        ?.parentElement;

      const spanTarget = (event.target as HTMLSpanElement).parentElement;

      if (
        !domNode.current.includes(event.target as HTMLButtonElement) &&
        !domNode.current.includes(pathTarget as HTMLButtonElement) &&
        !domNode.current.includes(spanTarget as HTMLButtonElement) &&
        !domNode.current.includes(svgTarget as HTMLButtonElement)
      ) {
        handleCloseDropDown();
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, [handleCloseDropDown, domNode]);

  const showUserInitials = (reference: string, title: ReactNode) => {
    if (reference === 'userDetails')
      return (
        <div className='user-initials'>{getUserInitials(title as string)}</div>
      );
    return title;
  };

  return (
    <>
      {menuData.map(
        (
          {
            id,
            path,
            title,
            reference,
            hasActiveDropdown,
            hasDropdown,
            dropDownValues,
          }: IData,
          index
        ) => (
          <div className='menu-wrapper' key={id}>
            <li className='menu-list'>
              {path !== 'none' ? (
                <button
                  id={id}
                  type='button'
                  data-testid='menu-btn'
                  ref={(element) => {
                    domNode.current[index] = element as HTMLButtonElement;
                  }}
                  onClick={() => handleDropDown(id)}
                  className={`menu-btn ${
                    reference === 'login' ? 'menu-link' : 'menu-tags'
                  } ${path === pathname ? 'btn-active' : ''}`}>
                  {path ? (
                    <div
                      className={`${reference === 'login' && 'animated-link'}`}>
                      {id === 'mail' || id === 'phone' ? (
                        <Link href={path}>{title}</Link>
                      ) : (
                        <RouterLink to={path}>{title}</RouterLink>
                      )}
                    </div>
                  ) : (
                    showUserInitials(reference as string, title)
                  )}
                  {hasDropdown ? (
                    <span
                      className={`${
                        hasActiveDropdown ? 'rotate-180-deg' : ''
                      }`}>
                      <SolidDownAngle fill='white' width={9} height={9} />
                    </span>
                  ) : (
                    ''
                  )}
                </button>
              ) : null}
            </li>

            <div
              data-testid='header-dropdown'
              className={`header-dropdown header-link-element ${
                hasActiveDropdown && 'header-dropdown-open'
              }`}>
              {reference === 'settings' ? (
                <ul
                  className={`settings-header ${
                    hasActiveDropdown && 'settings-header-open'
                  }`}>
                  <SettingsDropdown
                    title='Help & Support'
                    listTitle='support'
                    dropDownValues={dropDownValues || []}
                  />
                  {selectedProductName === EximProducts.DUTY_DRAWBACK && (
                    <SettingsDropdown
                      title='Duty Drawback Settings'
                      listTitle='duty-drawback'
                      dropDownValues={dropDownValues || []}
                      hasDivider
                    />
                  )}
                  <SettingsDropdown
                    title='General Settings'
                    listTitle='general'
                    dropDownValues={
                      shouldCompanyProfileShow
                        ? [
                            {
                              id: '3',
                              value: ProfileTabs.COMPANY_PROFILE,
                              path: `${Path.PROFILE}${Path.COMPANY_PROFILE}`,
                              listTitle: 'general',
                              icon: <CompanyProfile />,
                            },
                            ...settingsDropdown.DASHBOARD.options,
                          ]
                        : [
                            {
                              id: '4',
                              value: ProfileTabs.UNIFIED_USER_MANAGEMENT,
                              path: `${Path.PROFILE}${Path.UNIFIED_USER_MANAGEMENT}`,
                              listTitle: 'general',
                              icon: <UserManagement />,
                            },
                            ...settingsDropdown.DASHBOARD.options,
                          ] || []
                    }
                    hasDivider
                  />
                </ul>
              ) : (
                <ul
                  className={`header-dropdown-wrapper ${
                    hasActiveDropdown ? 'header-dropdown-open' : ''
                  }`}>
                  {/* {dropDownValues?.map((item) => (
                    <DropdownList item={item} key={item.id} />
                  ))} */}
                </ul>
              )}
              {reference === 'userDetails' ? (
                <div className='user-profile-header-dropdown header-link-element'>
                  <ul
                    className={`header-dropdown-wrapper user-header-dropdown ${
                      hasActiveDropdown ? 'header-dropdown-open' : ''
                    }`}>
                    <UserHeaderDropdown userName={title as string} />
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        )
      )}
    </>
  );
}

export default memo(Menu);
