import {lazy} from 'react';

const ROUTES = [
  {
    path: '/*',
    exact: false,
    component: lazy(() => import('../pages/ErrorPage404')),
    name: 'ErrorPage',
    isPrivate: false,
  },
  {
    path: '/registration',
    exact: true,
    component: lazy(() => import('../pages/Auth/components/Registration')),
    name: 'RegistrationPage',
    isPrivate: false,
  },
  {
    path: '/emailactivation',
    exact: true,
    component: lazy(() => import('../pages/Auth/components/EmailActivation')),
    name: 'EmailActivation',
    isPrivate: false,
  },
  {
    path: '/email-verification',
    exact: true,
    component: lazy(() => import('../pages/Auth/components/EmailVerification')),
    name: 'EmailVerification',
    isPrivate: false,
  },
  {
    path: '/add-user-verification',
    exact: true,
    component: lazy(
      () => import('../pages/Auth/components/AddUserVerification')
    ),
    name: 'AddUserVerification',
    isPrivate: false,
  },
  {
    path: '/',
    exact: true,
    component: lazy(() => import('../pages/Auth/components/Login')),
    name: 'LogIn',
    isPrivate: false,
  },
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('../pages/Auth/components/Login')),
    name: 'LogInHome',
    isPrivate: false,
  },
  {
    path: '/forgot-password',
    exact: true,
    component: lazy(() => import('../pages/Auth/components/ForgotPassword')),
    name: 'ForgotPassword',
    isPrivate: false,
  },
  {
    path: '/reset-password',
    exact: true,
    component: lazy(() => import('../pages/Auth/components/ResetPassword')),
    name: 'ResetPassword',
    isPrivate: false,
  },
  {
    path: '/dashboard',
    exact: true,
    component: lazy(() => import('../pages/Dashboard/container')),
    name: 'Dashboard',
    isPrivate: true,
  },
  // ==================== DATA EXTRACTOR ROUTES ===================
  {
    path: '/data-extractor',
    exact: true,
    component: lazy(
      () => import('../pages/DataExtractor/container/DataExtractorDashboard')
    ),
    name: 'DataExtractor',
    isPrivate: true,
  },
  {
    path: '/data-extractor/upload-process/:fileType',
    exact: true,
    component: lazy(
      () => import('../pages/DataExtractor/container/UploadProcess')
    ),
    name: 'DE_UploadProcess',
    isPrivate: true,
  },
  {
    path: '/data-extractor/advance-export/:fileType',
    exact: true,
    component: lazy(
      () => import('../pages/DataExtractor/container/AdvanceExport')
    ),
    name: 'DE_AdvanceExport',
    isPrivate: true,
  },
  {
    path: '/data-extractor/view-upload-details/sb/:id',
    exact: true,
    component: lazy(
      () => import('../pages/DataExtractor/container/ViewUploadDetails')
    ),
    name: 'ViewUploadDetailsSB',
    isPrivate: true,
  },
  {
    path: '/data-extractor/view-upload-details/boe/:id',
    exact: true,
    component: lazy(
      () => import('../pages/DataExtractor/container/ViewUploadDetails')
    ),
    name: 'ViewUploadDetailsBOE',
    isPrivate: true,
  },
  {
    path: '/data-extractor/export-history/:queryParam',
    exact: true,
    component: lazy(
      () => import('../pages/DataExtractor/container/ExportHistory')
    ),
    name: 'ExportHistory',
    isPrivate: true,
  },
  // ==================== DUTY DRAWBACK ROUTES ===================
  {
    path: '/duty-drawback/view-details/:fileType/:id',
    exact: true,
    component: lazy(
      () => import('../pages/DutyDrawback/container/ViewInvoiceDetails')
    ),
    name: 'DD_ViewDetails',
    isPrivate: true,
  },
  {
    path: '/duty-drawback/upload-process/:fileType',
    exact: true,
    component: lazy(
      () => import('../pages/DutyDrawback/container/UploadProcess')
    ),
    name: 'DD_UploadProcess',
    isPrivate: true,
  },
  {
    path: '/duty-drawback',
    exact: true,
    component: lazy(
      () => import('../pages/DutyDrawback/container/DutyDrawbackDashboard')
    ),
    name: 'DutyDrawback',
    isPrivate: true,
  },
  {
    path: '/duty-drawback/view-invoices/:fileType',
    exact: true,
    component: lazy(
      () => import('../pages/DutyDrawback/container/ViewInvoices')
    ),
    name: 'DD_ViewInvoices',
    isPrivate: true,
  },
  {
    path: '/duty-drawback/dbk-claim/:claimStep',
    exact: true,
    component: lazy(() => import('../pages/DutyDrawback/container/DbkClaim')),
    name: 'DbkClaim',
    isPrivate: true,
  },
  {
    path: '/duty-drawback/dbk-claim/bom-review/view-details',
    exact: true,
    component: lazy(
      () => import('../pages/DutyDrawback/components/ViewBOMReview')
    ),
    name: 'DbkClaimBOMReview',
    isPrivate: true,
  },
  {
    path: '/duty-drawback/view-invoices/:fileType/view-details',
    exact: true,
    component: lazy(
      () => import('../pages/DutyDrawback/components/ViewBOMReview')
    ),
    name: 'BOM_ViewInvoices',
    isPrivate: true,
  },
  {
    path: '/duty-drawback/claim-details/:claimId',
    exact: true,
    component: lazy(
      () => import('../pages/DutyDrawback/container/ClaimDetails')
    ),
    name: 'ClaimDetails',
    isPrivate: true,
  },

  // ==================== MOOWR ROUTES ===================
  {
    path: '/moowr',
    exact: true,
    component: lazy(() => import('../pages/Moowr/container/MoowrDashboard')),
    name: 'MoowrDashboard',
    isPrivate: true,
  },
  {
    path: '/moowr/transaction/:transactionStep',
    exact: true,
    component: lazy(() => import('../pages/Moowr/container/MoowrTransaction')),
    name: 'DbkClaim',
    isPrivate: true,
  },
  {
    path: '/moowr/transaction/bom-review/view-details',
    exact: true,
    component: lazy(() => import('../pages/Moowr/components/ViewBOMReview')),
    name: 'DbkClaimBOMReview',
    isPrivate: true,
  },
  // Todo: this route is only for testing only we will remove this route on production
  {
    path: '/add-test-details',
    exact: true,
    component: lazy(
      () => import('../pages/DutyDrawback/components/TestComponent')
    ),
    name: 'TestComponent',
    isPrivate: true,
  },
  // ==================== EXIM SUBSCRIPTION ROUTES ===================
  {
    path: '/subscription',
    exact: true,
    component: lazy(
      () => import('../pages/Subscription/container/BusinessDetails')
    ),
    name: 'BusinessDetail',
    isPrivate: true,
  },
  {
    path: '/subscription/view-plan',
    exact: false,
    component: lazy(() => import('../pages/Subscription/container/ViewPlans')),
    name: 'viewPlan',
    isPrivate: false,
  },
  {
    path: '/subscription/setup-process-select-addon',
    exact: true,
    component: lazy(
      () => import('../pages/Subscription/container/SetupProcessSelectAddon')
    ),
    name: 'SetupProcessSelectAddon',
    isPrivate: true,
  },
  {
    path: '/subscription/setup-process-payment-details',
    exact: true,
    component: lazy(
      () => import('../pages/Subscription/container/SetupProcessPaymentDetails')
    ),
    name: 'SetupProcessPaymentDetails',
    isPrivate: true,
  },
  // ==================== USER PROFILE ROUTES ===================
  {
    path: '/profile/:view',
    exact: true,
    component: lazy(() => import('../pages/Profile/container')),
    name: 'userProfile',
    isPrivate: true,
  },
  {
    path: '/profile/user-management/add-user',
    exact: true,
    component: lazy(() => import('../pages/Profile/container/AddUser')),
    name: 'addUser',
    isPrivate: true,
  },
];

export default ROUTES;
