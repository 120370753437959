import {createSlice} from '@reduxjs/toolkit';

export interface ITxnDetails {
  txnId: string;
  txnName: string;
  txnType: string;
  fromDate: string;
  toDate: string;
  isTxnExist: boolean;
}

interface IInitialState {
  panNumber: string;
  iecNumber: string;
  businessName: string;
  invoiceTxnId: string;
  currTransactionDetails: ITxnDetails;
  getTransactionHistory: () => void;
}

export const initialTxnDtls = {
  txnId: '',
  txnName: '',
  txnType: '',
  fromDate: '',
  toDate: '',
  isTxnExist: false,
};

const initialState: IInitialState = {
  panNumber: '',
  iecNumber: '',
  businessName: '',
  invoiceTxnId: '',
  currTransactionDetails: initialTxnDtls,
  getTransactionHistory: () => {
    // INFO: set the function because need to call that function after discard the transaction to update the table data
  },
};

const moowr = createSlice({
  name: 'moowr',
  initialState,
  reducers: {
    setPanNumber(state, actions) {
      state.panNumber = actions.payload;
    },
    setIecNumber(state, actions) {
      state.iecNumber = actions.payload;
    },
    setBusinessName(state, actions) {
      state.businessName = actions.payload;
    },
    setInvoiceTxnId(state, actions) {
      state.invoiceTxnId = actions.payload;
    },
    setCurrTransactionDetails(state, actions) {
      state.currTransactionDetails = actions.payload;
    },
    setGetTransactionHistoryFunc(state, action) {
      state.getTransactionHistory = action.payload;
    },
  },
});

const moowrReducer = moowr.reducer;

export const moowrActions = moowr.actions;
export default moowrReducer;
